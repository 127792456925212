<template>
  <div id="nav-panel">
    <div class="inner">
      <nav class="primary navigation " role="navigation">
        <ul class="menu">

          <li class="menu-item   " style="-webkit-animation-delay: 0.2s; animation-delay: 0.2s;">
            <a :href="$router.resolve({ name: 'home' }).href">{{ $t('navbar.home') }}</a>
          </li>

          <li class="menu-item  " style="-webkit-animation-delay: 0.3s; animation-delay: 0.3s;">
            <a @click="redirectTo(1)">{{ $t('navbar.gargantuan') }}</a>
          </li>

          <li class="menu-item  " id="mtid-item" style="-webkit-animation-delay: 0.3s; animation-delay: 0.3s;">
            <a @click="redirectTo(0)">{{ $t('navbar.mtid') }}</a>
          </li>

          <li class="menu-item  " style="-webkit-animation-delay: 0.4s; animation-delay: 0.4s;">
            <a @click="switchTo('about')">{{ $t('navbar.about') }}</a>
          </li>

          <li class="menu-item  " style="-webkit-animation-delay: 0.5s; animation-delay: 0.5s;">
            <a @click="switchTo('career')">{{ $t('navbar.careers') }}</a>
          </li>

        </ul>
      </nav>
      <div class="lang-select">
        <el-button type="text" size="large" @click="goToEng">English</el-button>
        <el-button type="text" size="large" disabled>简体中文</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { globalBus } from "@/assets/js/globalBus";

export default {
  name: "Navbar",
  methods: {
    switchTo: function (page) {
      globalBus.$emit('switchTo', page)
    },
    redirectTo: function (rid) {
      if (rid === 0) {
        window.open('https://windland.lilyn.cn', '_blank')
      } else if (rid === 1) {
        window.open('../gargantuan', '_blank')
      }
    },
    goToEng: function () {
      window.open('https://i.lilyn.cn', '_self')
    }
  }
}
</script>

<style scoped>
#nav-panel {
  background: rgba(30, 30, 37, 0.70);
}

a:hover {
  cursor: pointer;
}

@media screen and (min-width: 50rem) {
  #nav-panel .menu li {
    font-size: 70px;
    margin: 12px auto;
    letter-spacing: 100px;
  }
}

@media screen and (max-width: 768px) {
  #nav-panel .menu li {
    margin: 10px auto;
  }

  #nav-panel .menu li a {
    position: relative;
    display: block;
    text-align: left;
    font-size: 50px;
    letter-spacing: 5px;
    font-weight: 400;
  }

  #mtid-item {
    font-size: 26px !important;
  }
}

.lang-select {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.lang-select button {
  font-size: 15pt;
}
</style>