<template>
  <div class="landing-page " ref="scrollContainer">
    <!-- Logo -->
    <div class="title-container">
      <img class="title-container_topLine" src="https://cdn.lilyn.cn/images/juwu/promote/logo-line.svg" />
      <div class="title-container_line limit-width" />
      <img style="margin-bottom: 48px;" src="https://cdn.lilyn.cn/images/juwu/promote/gargantuan-logo.svg" alt="Logo"
        class="animated-logo">
      <img class="waiting-img" style="object-fit: contain; width: 100%;height: auto;"
        src="https://cdn.lilyn.cn/images/juwu/promote/waitImg.png" />
    </div>
    <div class="hr my-xl" />
    <!-- 背景故事 Section -->
    <section class="background-story ">

      <g-title class="fade-text" text="背景故事" />
      <div v-for="(storyblock, blockIndex) in background" :key="blockIndex" class="story-block">
        <span class="line-height-17 letter-spacing-3 text-3 fade-text" v-for="(sentence, sentenceIndex) in storyblock"
          :key="sentenceIndex">{{ sentence }}
        </span>
      </div>
    </section>

    <div class="hr my-xl" />
    <!-- 势力 Section -->
    <section class="powers limit-width">

      <g-title class="fade-text" text="势力" />

      <div class="power-wrapper">
        <div class="power-wrapper_left ">
          <svg width="39" height="821" viewBox="0 0 39 821" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37 2L2 37V784L37 819" stroke="#3B4254" />
            <path d="M37 2L2 37V79" stroke="white" stroke-width="4" />
            <path d="M37 819L2 784V742" stroke="white" stroke-width="4" />
          </svg>

        </div>

        <div class="power-wrapper_center  power-scrollbar ">
          <div v-for="(power, index) in pwoerrs" :key="index" class="power-container fade-image"
            @mouseover="hover = index" @mouseleave="hover = null" @touchstart="hover = index" @touchend="hover = null">
            <img ref="powerImg" :src="power.image" :alt="power.name" class="power-container_image">
            <div class="overlay" :class="{ 'overlay-hover on-after': hover === index }">
              <div class="power-name letter-spacing-3 line-height-15 text-2">{{ power.name }}</div>
              <p class="letter-spacing-3 line-height-20 text-3">{{ power.description }}</p>
            </div>
          </div>
        </div>


        <div class="power-wrapper_right">
          <svg width="39" height="821" viewBox="0 0 39 821" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L37 37V784L2 819" stroke="#3C4153" />
            <path d="M2 2L37 37V79" stroke="white" stroke-width="4" />
            <path d="M2 819L37 784V742" stroke="white" stroke-width="4" />
          </svg>
        </div>


      </div>
    </section>


    <div class="hr my-xl" />
    <!-- 角色 Section -->
    <section class="limit-width">
      <g-title class="fade-text" text="角色" />
      <div class="characters ">
        <Character v-for="(character, index) in characterss" :key="index" :avatar="character.avatar"
          :description="character.description" :name="character.name" class="fade-image" />
      </div>

    </section>


    <div class="hr my-xl" />
    <!-- 剧照 Section -->
    <section class="stills" style="margin-bottom: 194px;">
      <g-title class="fade-text" text="剧照" />

      <div style="position: relative;" class="fade-image">
        <div class="swiper-button-prev" style="color: white;" />
        <div class="swiper-button-next" style="color: white;" />
        <swiper ref="swiper" class="swiper" :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="(item, index) in swiperItems" :key="index"><img :src="item.image"
              alt="剧照" /></swiper-slide>
          <div class="swiper-pagination  swiper-pagination-white" slot="pagination"></div>
        </swiper>
      </div>
    </section>



    <!-- Footer -->
    <footer style="display: flex; flex-direction: column; align-items: center; margin-bottom: 48px;">
      <img style="margin-bottom: 16px;" src="https://cdn.lilyn.cn/images/juwu/promote/lilyn-logo.svg"
        alt="Lilyn Entertainment" class="footer-logo">
      <span>理灵文化版权所有</span>
    </footer>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { gsap, } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { PROMOTE } from '../../constant/cdnURL'

import 'swiper/css/swiper.css'

// Import Swiper styles
// import videojs from 'video.js';

import 'video.js/dist/video-js.css'; // Import Video.js styles
import GTitle from '../../components/gargantuan/GTitle.vue';
import Character from '../../components/gargantuan/Character.vue';



gsap.registerPlugin(ScrollTrigger);

export default {
  name: "gargantuan",
  components: {
    Swiper,
    SwiperSlide,
    GTitle,

    Character
  },
  data() {
    return {
      hover: null,
      swiper: null,
      fadeElements: null,
      scrollContainer: null,
      powerImg: null,
      background: [
        [
          "新纪元年，",
          "一种被科学家称为光蚀体的生命在地球疯狂肆虐，", "短短三十年，人类就失去95%的生存区域。",
        ],
        [
          "幸存者们被逼入最后的安全区，",
          "挣扎求存，",
          "他们寄希望于科学家们可以力挽狂澜，然而却低估了人性的丑恶。",
        ],
        [
          "在人为干预下，",
          "用于揭示光蚀体运作机制的B-37凝聚炉发生爆炸，",
          "不仅造成了上千万的人死亡，",
          "还昭示着人类又一次的自救失败。"
        ],
        [
          "岌岌可危的势态将各大势力之间的阴谋对峙拽向明处，",
          "他们为了各自的隐秘计划，对地球上仅剩的资源展开了更残酷的争夺，",
          "而那些原本残喘于光蚀沙漠边缘的流亡者们则成了他们的斗争的牺牲品。"
        ],
        [
          "然而，",
          "随着光蚀体集体性的突发变异，",
          "一个所有人都讳莫如深的残酷现实再也无法隐藏，",
          "人类这个物种或将就此走向末日，",
          "即使是隐藏最深的阴谋家也将感受到深刻的绝望。"
        ]
      ],
      powers: [
        {
          id: 'yongzhou',
          name: '永昼',
          description: '永昼是人类最后的安全区，董盛任政治领袖，实权却被一个叫SHAX的科技公司掌握。在光蚀体入侵的第25年，SHAX建成了代号Imprisia的新一代人工智能，为人类对抗光蚀体带来了希望。 '
        },
        {
          id: 'tunxing',
          name: '吞星',
          description: '吞星起源于一家生物科技公司。在光蚀灾难发生后，吞星长期蛰伏于世界上某个隐秘的角落，除了极少数的相关人员外，没有人知道它的具体位置。偶尔有从光蚀沙漠死里逃生的探险者在描述他们时，都会提起他们皮肤上似乎有生命的诡异纹身。'
        },
        {
          name: '流民',
          id: 'liumin',
          description: '流民是光蚀沙漠中的流亡者，他们因为各种各样的原因没有进入永昼的体系，只能在光蚀体较少的区域挣扎求生。极致的恐惧激发了前所未有的求生意志。因此，他们甚至比永昼的调查队更加擅长在这片沙漠里求生。'
        },
        {
          name: '余人会',
          id: 'yurenhui',
          description: '余人会以余恕为核心构建的一个科学组织，他们不参与任何世俗的斗争，唯一的目的就是研究光蚀体。'
        },
      ],
      characters: [
        {

          id: 'yushu',
          name: '余恕',
          description: '一名顶尖科学家，一个追求真相的人，他几乎摈弃了所有的普世情感，把所有的精力都投入进了对光蚀体的研究中，他认为其中包含着颠覆一切既有认知的秘密。'
        },
        {
          id: 'kexin',
          name: '可欣',
          description: '前永昼居民，曾因盗窃被永昼判为B级通缉犯，实际上是秘密科研组织余人会的成员，为了调查父母死亡的真相和哥哥廖远一起潜入太空监狱救援被永昼囚禁的余恕。'
        },
        {

          name: '廖远',
          id: 'liaoyuan',
          description: '永昼民间商会的一员，深刻体会光蚀体给人们来带的痛苦，继承父母的意志设法寻找消灭光蚀体的方法。前往太空监狱救援唯一可能知道消灭光蚀体方法的科学家余恕。'
        },
        {

          name: '梁月',
          id: 'liangyue',
          description: '流民出身，患有先天肌肉萎缩，她自小流浪街头，濒死关头以自己的意志和超常智力获得了余恕的认可，成为了余恕的学生。在B-37大爆炸之后，梁月集结廖远和可欣等人一起前往太空监狱救援自己的老师。'
        },
        {

          id: 'fangzhang',
          name: '方障',
          description: '永昼合法居民，精通驾驶各种载具，“好又快”修车行老板的儿子，机车组织“Lighting Machine”资深成员。曾因一月内132次违章驾驶，被永久吊销驾驶执照。'
        },
        {

          name: '阿奇 and 狗哥',
          id: 'aqiagou',
          description: '阿奇和狗哥都是机器人。机缘巧合下两者共用一个躯体。阿奇原本是量产型娱乐机器人，经过改装后安装了“Cracker”功能插件，成为了一个破解各种网络系统的大师。'
        },
      ],
      stills: [],
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        centeredSlides: true,
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        }
      }
    }
  },

  mounted() {
    this.setupScrollTriggers()
    const image = 'http://cdn.lilyn.cn/gargantuan/images/bg.webp';

    this.load(image).then(() => {
      document.getElementById('project-container').style.backgroundImage = `url(${image})`;
    });

    // this.player = videojs(this.$refs.videoPlayer, {
    //   autoplay: false,
    //   fluid: true,
    //   controls: true
    // })


    // // You can listen to Video.js events and perform actions
    // // For example, listen to the 'play' event
    // this.player.on('play', () => {
    //   console.log('Video is playing');
    // });

    // // If you want to dispose of the player when the component is destroyed
    // this.$on('hook:beforeDestroy', () => {
    //   if (this.player) {
    //     this.player.dispose();
    //   }
    // });

  },
  computed: {
    pwoerrs() {
      const p = this.powers.map((pow) => {
        pow['image'] = `${PROMOTE}${pow.id}.png`
        return { ...pow }
      }
      )
      return p
    },
    characterss() {
      // if (this.characters.length)
      return this.characters.map(char => {
        char['avatar'] = `${PROMOTE}characters/${char.id}.png`
        return { ...char }
      })
    },
    swiperItems() {
      const images = []
      const imgNum = 10; //十张轮播题
      for (let i = 1; i <= imgNum; i++) {
        images.push({ image: `${PROMOTE}carousel/image${i}.png` })
      }
      return images
    }
  },
  methods: {
    load() {
    },
    setupScrollTriggers() {
      const sections = document.querySelectorAll('section')
      sections.forEach((section) => this.fade(section))
    },
    fade(el) {
      const text = el.querySelectorAll('.fade-text')
      const image = el.querySelectorAll('.fade-image')
      // console.log({ el })
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top 75%',
          ease: "power3",
          toggleActions: "play none none reverse"
        },

      })

      timeline.from(text, {
        y: 20,
        opacity: 0,
        duration: 0.5,
        stagger: 0.2
      }, "-=0.2");
      timeline.from(image, {
        y: 20,
        opacity: 0,
        duration: 0.2,
        stagger: 0.3
      })
      return timeline
    }

  },
  watch: {
    hover: function (val) {

      if (val !== null) {
        this.$refs.powerImg[val].classList.add('hover')
      } else {
        this.$refs.powerImg.forEach((img) => {
          const classList = Array.from(img.classList)
          if (

            classList.find((className) => className === 'hover')
          ) {
            img.classList.remove('hover')
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import url(./media.css);
@import url(../../assets/css/customHTML.css);

/** 内容最大宽度 */
$limit-content-width: 1300px;
$border-color: #292A33;

/**
  线条渐变颜色
*/
@function gradient-line($opacity: 0.3) {
  @return rgba(203, 208, 255, $opacity);
}


@font-face {
  font-family: AlimamaFangYuan;
  src: url('https://cdn-font-cf.lilyn.ai/AlimamaFangYuanTiVF-Thin.ttf');
}

@font-face {
  font-family: WD-XL;
  src: url('https://cdn-font-cf.lilyn.ai/WD-XLLubrifontSC-Regular.otf');
}

:global(body) {
  font-family: AlimamaFangYuan, sans-serif;
}


p {
  margin: 0;
  font-family: AlimamaFangYuan, sans-serif;
}

.landing-page {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(0, 0, 0);
}

.title-container {
  /* 首图作为header需要全屏 */
  width: 100vw;
  display: flex;
  padding-top: 48px;
  flex-direction: column;
  align-items: center;
  background-image: url('https://cdn.lilyn.cn/images/juwu/promote/background-title.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &_line {
    top: 70px;
    position: absolute;
    height: 600px;
    width: $limit-content-width;
  }

  &_line::before,
  &_line::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: linear-gradient(gradient-line(0), gradient-line(), gradient-line(0));
  }

  &_line::after {
    left: 0;
  }

  &_line::before {
    right: 0;
  }

}

.logo-container {
  margin: 20px 0;
}

.animated-logo {
  /* container的padding-top是48，UI图上是117，这里应该是117-48=69 */
  margin-top: 69px;
}

.video-placeholder {
  margin-top: 42px;
}

.video-container video {
  max-width: 900px;
  /* 可根据需要调整最大宽度 */
}

.background-story {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.powers .image-container {
  display: flex;
  justify-content: center;
}


.character-card {
  display: inline-block;
  position: relative;
  flex-direction: column;
  padding-bottom: 24px;
  color: #fff;
  height: 100%;
  background-image: url('https://cdn.lilyn.cn/images/juwu/promote/characters_border.svg');
  background-repeat: no-repeat;
  background-size: contain;


  &_container {
    position: relative;


  }

  &_container img {
    display: block;
    height: auto;
  }
}

:deep(.swiper-pagination-bullet) {
  background: #fff !important;
}

footer {
  padding: 20px;
  color: darkgray;
}

.footer-logo {
  width: 138px;
  height: auto;
}

.power-wrapper {
  display: flex;
  position: relative;
  align-items: center;

  &_center {
    display: flex;
    gap: 48px;
    overflow-y: hidden;
    overflow-x: auto;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.power-container {
  position: relative;
  display: inline-block;
  overflow-y: hidden;
  /** 防止收缩 */
  flex-shrink: 0;
  height: 750px;


  &_image {
    height: 750px;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &_image.hover {
    opacity: 0.6;
    // height: 750px;
  }
}



.overlay {
  padding: 24px 16px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #737373;
  backdrop-filter: blur(8px);
  color: white;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  transform: translateY(75%);
  overflow: hidden;
}


.overlay-hover {
  transform: translateY(0%);
  transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}



.overlay div {
  background-image: url('https://cdn.lilyn.cn/images/juwu/promote/shiliTitle.png');
  background-repeat: no-repeat;
  background-size: contain;
  /* background-size: 88px 20px; */
  /* 图片本身尺寸的一半 */
  background-attachment: fixed;
  background-position: center center;
  margin-bottom: 24px;
}

.overlay p {
  position: relative;
}

.overlay::after {
  content: '';
  position: absolute;
  bottom: 500px;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0));
  pointer-events: none;
  z-index: 1;
}

.overlay.on-after::after {
  content: none;
}


.background-story .story-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 30px;
}

.border-angle {
  position: absolute;
}

.power-angle {
  width: 20px;
  height: 2px;
  position: absolute;
  background-color: #fff;
  transform: rotate(90deg);
}

.power-angle:first-of-type::before {
  content: '';
  display: block;
  width: 25px;
  height: 2px;
  background-color: #fff;
  transform-origin: left;
  transform: rotate(230deg);
}

.power-angle:nth-of-type(2)::before {
  content: '';
  display: block;
  width: 25px;
  height: 2px;
  background-color: #fff;
  transform-origin: left;
  transform: rotate(130deg);
}

.power-angle:nth-of-type(3)::before {
  content: '';
  display: block;
  width: 21px;
  height: 2px;
  background-color: #fff;
  transform-origin: right;
  transform: rotate(130deg);
}

.power-angle:nth-of-type(4)::before {
  content: '';
  display: block;
  width: 21px;
  height: 2px;
  top: 6px;
  background-color: #fff;
  transform-origin: right;
  transform: rotate(230deg);
}


.power-name {
  font-family: WD-XL, sans-serif;
}


.characters-title {
  font-family: WD-XL, sans-serif;
}



/* global  */
.hr {
  background: linear-gradient(to left, gradient-line(0), gradient-line(), gradient-line(0));
  width: 70%;
  height: 1px;
}

.scroll-container {
  // overflow-y: auto;
  // height: 100vh;
}

.fade-element {
  opacity: 0;
  transition: opacity 0.3s;
}

.limit-width {
  max-width: $limit-content-width;
}

.text-color-white {
  color: #fff;
}
</style>