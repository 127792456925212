<template>
    <div class="character" :style="{ backgroundImage: 'url(' + avatar + ')' }">
        <div class="character-contain">
            <div class="character-contain-name letter-spacing-1 text-2">{{ name }}</div>
            <div class="character-contain-description line-height-20 letter-spacing-1 text-3">{{ description }}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Character',
    props: {
        avatar: String,
        name: String,
        description: String
    }
}
</script>
<style lang="scss" scoped>
.character {
    height: var(--character-height);
    // width: var(--character-height);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-contain {
        margin-top: calc(var(--character-height) / 2.25);
        padding: 24px;

        &-name {
            font-family: WD-XL, sans-serif;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }


}
</style>