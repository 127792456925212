<template>
  <div id="sitefooter" class="clearfix" role="contentinfo">
    <div class="inner footer-inner">
      <!--      语言选择-->
      <div class="language-pick-box" style="text-align: left;color: white;">
        <a href="https://beian.miit.gov.cn">浙ICP备2022005303号-1</a>
        <!--        <el-dropdown @command="selectLanguage">-->
        <!--          <span class="el-dropdown-link">-->
        <!--           {{ $t('selectLanguage') }} <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--          </span>-->
        <!--          <el-dropdown-menu slot="dropdown">-->
        <!--            <el-dropdown-item v-for="{locale, name} in [-->
        <!--                {locale: 'zh', name: '中文简体'},-->
        <!--                {locale: 'en', name: 'English'}-->
        <!--            ]" :key="locale" :command="locale">{{ name }}</el-dropdown-item>-->
        <!--          </el-dropdown-menu>-->
        <!--        </el-dropdown>-->
      </div>
      <!--      备案信息-->
      <div class="copyright-box">
        <!--        <a href="https://beian.miit.gov.cn">ICP备案号：浙ICP备2022005303号-1</a><br>-->
        <a href="/">&copy; {{ currYear }} {{ $t('companyShortName') }} {{ $t('copyright') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: function () {
    return { year: 0 }
  },
  mounted() {
    // window.onscroll = () => {
    //   console.log("scrolling....")
    //   document.getElementById("sitefooter").style.opacity = "0.5"
    // }
    // window.ontouchmove = () => {
    //   console.log("touching....")
    //   document.getElementById("sitefooter").style.opacity = "0.5"
    // }
    // window.ontouchend = () => {
    //   console.log("touching....")
    //   document.getElementById("sitefooter").style.opacity = "0"
    // }
  },
  methods: {
    selectLanguage(lang) {
      window.localStorage.lang = this.$i18n.locale = lang
    },
  },

  computed: {
    currYear: function () {
      const year = new Date().getFullYear()
      return year
    }
  }
}
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #e3e3e3;
}

.el-icon-arrow-down {
  font-size: 12px;
}

#sitefooter {
  margin-top: -7em;
  z-index: -99999999 !important;
  /*opacity: 0;*/
}

/*.active {*/
/*  opacity: 1;*/
/*}*/
.footer-inner {
  display: flex;
  justify-content: space-between;
}

.copyright-box {
  color: white;
}

@media screen and (max-width: 768px) {
  #sitefooter {
    margin-left: -36px;
    margin-right: -36px;
  }
}
</style>