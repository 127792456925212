export default {
    title: '理灵文化',
    selectLanguage: 'Not Your Language?',
    companyShortName: '理灵文化',
    copyright: '所有权利保留',
    navbar: {
        home: '首页',
        feed: '最近更新',
        about: '关于理灵',
        careers: '加入我们',
        gargantuan: '巨物动画',
        mtid: '虚拟人工作室',
    }
}