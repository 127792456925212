<template>
  <div>
    <div id="loader-screen">
      <div class="load-panel" id="load-panel"></div>
    </div>

    <div class="project-panel" id="project-panel">
      <div class="project-panel-background" id="project-panel-background" style=""></div>

      <div class="content" id="home-content">
        <div class="inner">
          <a href="../gargantuan" target="_blank">
            <img class="page-title" id="nephilims-logo" src="https://cdn.lilyn.cn/images/nephilims.svg" alt="nephilims">
          </a>
        </div>
      </div>

      <div class="content" id="about-us-content">
        <div class="inner">
          <div class="about-us">
            <div class="about-us-content-details">
              <img class="lilyn-text" src="https://cdn.lilyn.cn/images/lilyn-font.svg" alt="lilyn-text">
              <span class="lilyn-subtext">LILYN ENTERTAINMENT</span>

              <h5 id="test-output">关于我们</h5>
              <p>理灵源自《新世纪福音战士》中的第十八位使徒Lilin，意为“人类”。理灵是一家专注动画制作以及虚拟人IP生态的企业，我们的愿景是创造虚拟人类。</p>
              <p>目前主要由动画工作室（IPD）以及AI创新工作室（Lab 21g）组成。其中IPD专注于制作第一款原创动画《巨物》，Lab 21g则主要专注于研发人工智能相关的衍生产品。</p>
              <br>
              <h5>我们的团队</h5>
              <p>理灵文化本着“技美合一”的理念，把美术与技术融合。我们的团队来自腾讯、网易、Steam中国、字节跳动等公司，在动作生成、语音合成、大语言模型方面拥有自主探索与开发的能力；我们团队同样有来自国内知名动画公司追光动画、艺画开天、黑岩动画的成员，曾参与过《三体》、《灵笼》、《青蛇劫起》、《哪吒-重生》、英雄联盟CG《乘风归》等国内外一线动画制作。</p>
              <p>理灵文化在具备专业技术研发能力的同时，拥有领先的动画美术功底与动画项目经验。我们将技术植入美术，在成熟CG动画的基础上搭载AI技术，为的就是达成我们的愿景：创造虚拟人类。</p>
              <div class="third-party-zone third-party-zone-inner">
                <el-popover
                    placement="top"
                    width="160"
                    trigger="hover"
                    class="wechat-popover"
                >
                  <img class="third-party-qrcode wechat-qrcode" src="https://cdn.lilyn.cn/images/wechat-qrcode.png" alt="微信公众号二维码" style="width: 150px">
                  <div class="weixin-link" slot="reference" @click="jumpTo()">
                    <img src="https://cdn.lilyn.cn/images/weixin.svg" alt="weixin-link">
                  </div>
                </el-popover>

                <el-popover
                    placement="top"
                    width="160"
                    trigger="hover"
                >
                  <img class="third-party-qrcode weibo-qrcode" src="https://cdn.lilyn.cn/images/weibo-qrcode.png" alt="微博二维码" style="width: 150px">
                  <div class="sina-link" slot="reference">
                    <img src="https://cdn.lilyn.cn/images/sina.svg" alt="sina-link">
                  </div>
                </el-popover>
              </div>
            </div>
            <div class="third-party-zone third-party-zone-outer">
              <el-popover
                  placement="top"
                  width="160"
                  trigger="hover"
                  class="wechat-popover"
              >
                <img class="third-party-qrcode wechat-qrcode" src="https://cdn.lilyn.cn/images/wechat-qrcode.png" alt="微信公众号二维码" style="width: 150px">
                <div class="weixin-link" slot="reference" @click="jumpTo()">
                  <img src="https://cdn.lilyn.cn/images/weixin.svg" alt="weixin-link">
                </div>
              </el-popover>

              <el-popover
                  placement="top"
                  width="160"
                  trigger="hover"
              >
                <img class="third-party-qrcode weibo-qrcode" src="https://cdn.lilyn.cn/images/weibo-qrcode.png" alt="微博二维码" style="width: 150px">
                <div class="sina-link" slot="reference">
                  <img src="https://cdn.lilyn.cn/images/sina.svg" alt="sina-link">
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>

      <div class="content" id="career-content">
        <div class="inner">
          <div class="join-us">
            <img class="lilyn-text" src="https://cdn.lilyn.cn/images/lilyn-font.svg" alt="lilyn-text">
            <span class="lilyn-subtext">LILYN ENTERTAINMENT</span>
            <h5>加入我们</h5>
            <p>我们一直在寻找热爱合作的人才。如果您对动画充满热情并有兴趣加入我们，请联系我们。</p>
            <h5>IPD</h5>
            <div class="career-grid">
              <div class="career-grid-row">
                <div class="career-grid-column">
                  <div class="career-grid-item">执行导演</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">主美</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">视效总监</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">动画TA</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">动画TD</div>
                </div>
              </div>
              <div class="career-grid-row">
                <div class="career-grid-column">
                  <div class="career-grid-item">分镜师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">绑定师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">动画师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">解算师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">合成师</div>
                </div>
              </div>
              <div class="career-grid-row">
                <div class="career-grid-column">
                  <div class="career-grid-item">特效师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">灯光师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">科幻场景概念</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">科幻角色概念</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item"></div>
                </div>
              </div>
            </div>
            <h5>Lab 21g</h5>
            <div class="career-grid">
              <div class="career-grid-row">
                <div class="career-grid-column">
                  <div class="career-grid-item">NLP工程师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">AI产品经理</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">数据工程师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">全栈工程师</div>
                </div>
              </div>
            </div>
            <div class="career-contact">
              <div class="hr-qrcode-div">
                <img class="hr-qrcode" src="https://cdn.lilyn.cn/images/qrcode.png" alt="HR二维码">
              </div>
              <div class="hr-contact-info">
                <p class="contact-text">邮箱：jobs@lilyn.cn</p>
                <p class="contact-text">微信：Anges666-</p>
                <p class="contact-text">电话：13736232143</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <el-dialog title=""
               custom-class="lilyn-dialog"
               :visible.sync="customerFormVisible"
               :z-index="999999990"
               :center="true"
               :width="customerFormWidth"
    >
      <el-form :model="customerForm">
        <el-form-item label="姓名" label-width="100px">
          <el-input v-model="customerForm.name"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" label-width="100px">
          <el-input v-model="customerForm.company"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" label-width="100px">
          <el-input v-model="customerForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="您是如何得知理灵文化《巨物》" label-width="220px">
          <el-select v-model="customerForm.source" label-width="150px">
            <el-option label="请选择" value="0"></el-option>
            <el-option label="朋友推荐" value="1"></el-option>
            <el-option label="搜索引擎" value="2"></el-option>
            <el-option label="自媒体（微信公众号、36氪等）" value="3"></el-option>
            <el-option label="视频平台（B站、抖音等）" value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
<!--        <el-button @click="customerFormVisible = false">取消</el-button>-->
        <el-button class="customerform-button" @click="handleCustomerFormSubmit">提交</el-button>
      </div>
    </el-dialog>

    <el-dialog title=""
               custom-class="lilyn-dialog"
               :visible.sync="videoDialogVisible"
               :center="true"
               :modal-append-to-body="false"
               :append-to-body="false"
               :z-index="999999990"
               :before-close="handleVideoDialogClose"
               :width="videoDialogWidth"
    >
      <div @contextmenu.prevent="menuPlayer()">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="false"
                      :options="playerOptions"
        ></video-player>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Reveal from "reveal.js";
import {globalBus} from "@/assets/js/globalBus";
import "reveal.js/dist/reveal.css"
import "reveal.js/dist/theme/black.css"
import axios from "axios";

export default {
  name: "home",
  data: function () {
    return {
      bgImg: null,
      bgImgOrigWidth: 0,
      bgImgOrigHeight: 0,
      currentPage: 'init',
      bgPosY: 99999999,
      careerPos: -1220,
      aboutUsPos: 0,
      bgOffset: 0,
      contentOffset: 0,
      hImg: 0,
      hI: 0,
      adj: 20,
      customerForm: {
        name: '',
        company: '',
        contact: '',
        source: '0',
      },
      mainVideoTitle: '《巨物》非最终效果预告片',
      customerFormVisible: false,
      customerFormWidth: "0",
      videoDialogVisible: false,
      videoDialogWidth: "0",
      videoUrl: "",
      playerOptions: {
        autoplay: false,
        language: 'zh-CN',
        fluid: true,
        sources: [{
          type: "video/mp4",
          src: ''
        }],
        notSupportedMessage: '此视频暂时无法播放，请稍后重试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
    }
  },
  created() {
    this.switchTo();
  },
  mounted() {
    if (this.$isMobile()) {
      // this.adj = 20;
      this.adj = 20;
    } else {
      this.adj = 0;
    }

    const bgImg = new Image();
    if (window.innerWidth < window.innerHeight) {
      bgImg.src = "https://cdn.lilyn.cn/images/bg-mob.png";
    } else {
      bgImg.src = 'https://cdn.lilyn.cn/images/bg.webp';
    }
    let $this = this;
    bgImg.onload = function () {
      window.bgImg = bgImg
      $this.bgImgOrigWidth = bgImg.width;
      $this.bgImgOrigHeight = bgImg.height;
      $this.bgImg = bgImg;
      document.getElementById('project-panel-background').style.backgroundImage = `url(${bgImg.src})`;
      $this.initBackgroundImg();
      $this.initScrollBehavior();
      $this.showIntroTransition();
    }

    document.getElementById('home-content').style.display = "block";
    document.getElementById('about-us-content').style.display = "none";
    document.getElementById('career-content').style.display = "none";
    window.bgPosY = 99999999;

    this.initAppHeight();
    this.setDialogWidth();

    window.onresize = () => {
      console.log(this.getAvailableContent());
      if (window.innerHeight !== this.hI) {
        console.log("new innerHeight:" + window.innerHeight);
      }
      this.initAppHeight();
      this.initBackgroundImg();
      this.initScrollBehavior();
      this.resizeBG(this.getAvailableContent());
      this.setDialogWidth();
    }
    console.log(this.$isMobile());
  },
  computed: {
  },
  methods: {
    setDialogWidth() {
      console.log('Client Width: ', document.body.clientWidth)
      let val = document.body.clientWidth
      if (val < 800) {
        this.customerFormWidth = '100%'
        this.videoDialogWidth = '100%'
      } else {
        this.customerFormWidth = '400px'
        this.videoDialogWidth =  '800px'
      }
    },
    handleVideoDialogClose() {
      let $player = this.$refs.videoPlayer.player
      $player.pause()
      this.videoDialogVisible = false
    },
    handlePromoClick() {
      // 先检测Cookies
      // let isRegistered = this.$cookies.get('is-registered')
      // console.log('is reg?' + isRegistered)

      // 如果Cookies有值则直接跳转播放弹窗
      if (this.videoUrl !== "") {
        // let videoUrl = this.$cookies.get('video-url')
        this.playerOptions.sources[0].src = this.videoUrl
        this.videoDialogVisible = true
      } else {
        this.customerFormVisible = true
      }

      console.log("HI")
    },
    async handleCustomerFormSubmit () {
      let data = new FormData()
      for (let key in this.customerForm) {
        data.append(key, this.customerForm[key])
      }
      if (this.$cookies.get('localIp') !== null) {
        data.append('ip', this.$cookies.get('localIp'))
      } else {
        const respObj = await fetch('https://api.ipify.org?format=json', { method: 'GET' })
        const data = await respObj.json()
        const userIPAddress = data.ip
        data.append('ip', userIPAddress)
      }
      let url = "https://ddns.lilyn.cn:12443/api/video_request"
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      axios.post(url, data, config).then((res) => {
        if (res.data.code === 200) {
          if (res.data.msg === "ok") {
            this.videoUrl = res.data.data.video_url
            // cookie 记录 1 及 video_url
            // this.$cookies.set('is-registered', 1)
            // this.$cookies.set('video-url', videoUrl)
            // 弹出播放视频弹窗
            this.playerOptions.sources[0].src = this.videoUrl
            this.customerFormVisible = false
            this.videoDialogVisible = true
          } else {
            this.$alert('感谢您的兴趣，我们会尽快与您联系', '登记成功', {
              confirmButtonText: '好的',
              callback: () => {
                this.customerFormVisible = false
              }
            })
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    initScrollBehavior() {
      // 如果是手机等小屏幕并且横屏(innerWidth > innerHeight) 那么就解锁scroll-y
      if (this.$isMobile() && window.innerWidth > window.innerHeight) {
        console.log("移动设备 横屏状态");
      }
    },
    initBackgroundImg() {
      let bgImg = document.getElementById("project-panel-background")
      let hI = window.innerHeight;
      console.log("window.innerHeight: ", window.innerHeight);
      let hImg = window.innerWidth / this.bgImgOrigWidth * this.bgImgOrigHeight;
      this.hI = hI;
      this.hImg = hImg;
      bgImg.style.height = hImg + "px";

      if (this.currentPage === "init" || this.currentPage === "home") {
        let bgOffset = Math.round(-0.5 * hImg + 0.5 * hI + this.adj);

        let aniStyle = document.createElement('style');
        aniStyle.setAttribute('type', 'text/css');
        document.head.appendChild(aniStyle)
        let sheet = aniStyle.sheet;
        sheet.insertRule(
        `@keyframes INIT-MOVE {
              to {
                transform: translateY(` + bgOffset + `px);
            }`,0)

        bgImg.style.animation = "0.1s ease 0s normal forwards INIT-MOVE";
        this.currentPage = "home";
        this.bgOffset = bgOffset;
        this.contentOffset = bgOffset;
        if (this.$isMobile() && window.innerWidth < window.innerHeight) {
          console.log("mob and w < h")
          this.contentOffset = Math.round(bgOffset + (844 - hI) * 1.0);
          console.log("Mobile contentOffset: " + this.contentOffset)
        } else if (window.innerWidth < window.innerHeight) {
          console.log("w < h")
          this.contentOffset = Math.round(-hI * 0.75);
          console.log("Mobile contentOffset: " + this.contentOffset)
        } else if (this.$isMobile()) {
          console.log("mob")
          this.contentOffset = Math.round(-hI * 0.75);
          console.log("Mobile contentOffset: " + this.contentOffset)
        }

        console.log("Initial Background IMG height, innerHeight, image Offset:" + hImg, hI, bgOffset)
      }
    },
    initPopover() {
      if (this.isWechat()) {
        document.querySelector(".wechat-popover").setAttribute("disabled", "true");
      } else {
        document.querySelector(".wechat-popover").setAttribute("disabled", "false");
      }
    },
    initAppHeight() {
      const doc = document.documentElement;
      const vh = window.innerHeight;
      doc.style.setProperty('--app-height', `${vh}px`);
      console.log(`--app-height: ${vh}px`);
    },
    getAvailableContent() {
      if (document.getElementById('home-content').style.display === "block") {
        return 'home-content';
      } else if (document.getElementById('about-us-content').style.display === "block") {
        return 'about-us-content';
      } else {
        return 'career-content';
      }
    },
    resizeBG(contentId) {
      // 重新设置Content高度

      let aboutUsContentTop = 0;
      let careerContentTop = 0;

      if (this.$isMobile()) {
        aboutUsContentTop = -this.contentOffset+20;
        careerContentTop = -this.contentOffset+70;
      } else {
        aboutUsContentTop = -this.contentOffset;
        careerContentTop = -this.contentOffset+20;
      }

      if (contentId === 'career-content') {
        document.getElementById(contentId).style.top = String(careerContentTop) + "px";
        this.reformatJobLists()
      } else if (contentId === 'about-us-content') {
        document.getElementById(contentId).style.top = String(aboutUsContentTop) + "px";
        console.log("aboutusposY: ", this.aboutUsPos);
      }
    },
    reformatJobLists() {
      if (this.$isMobile()) {
        let careerGridRows = document.getElementsByClassName("career-grid-row");
        console.log(careerGridRows);
        let leftRow = careerGridRows[0];
        for (let i = 0; i < leftRow.childNodes.length; i++) {
          let leftCol = leftRow.childNodes[i];
          if (leftCol.classList.contains("column-align-right")) {
            leftCol.classList.remove("column-align-right");
          }
          leftCol.classList.add("column-align-left");
        }

        let rightRow = careerGridRows[1];
        for (let i = 0; i < rightRow.childNodes.length; i++) {
          let rightCol = rightRow.childNodes[i];
          if (rightCol.classList.contains("column-align-left")) {
            rightCol.classList.remove("column-align-left");
          }
          rightCol.classList.add("column-align-right");
        }
      } // todo: PC版的布局
    },
    loaderComplete() {
      console.log('loader-screen removed');
      //document.querySelector('#loader-screen').remove();
      document.querySelector('#loader-screen').classList.add('hidden');
      document.body.classList.remove('no-scroll');
    },
    showIntroTransition() {
      let $this = this;
      console.log('trigger intro transition');
      setTimeout(function(){
        document.querySelector('.load-panel').classList.add('reveal');
        let slider = document.createElement('div')
        slider.classList.add('slides')
        document.getElementById('load-panel').appendChild(slider);
        Reveal.initialize();
      }, 1000);
      setTimeout(function(){
        $this.loaderComplete();
      }, 2500);
    },
    switchTo() {
      globalBus.$on('switchTo', (page) => {
        console.log("switched to" + page)
        console.log("Adj amount: " + this.adj)
        this.$data.currentPage = page;

        if (page === 'about') {
          // 隐藏home和careers
          document.getElementById('home-content').style.display = "none";
          document.getElementById('about-us-content').style.display = "block";
          document.getElementById('career-content').style.display = "none";

          // 添加动画
          let aniStyle = document.createElement('style');
          aniStyle.setAttribute('type', 'text/css');
          document.head.appendChild(aniStyle)
          let sheet = aniStyle.sheet;
          let aboutUsPos = -0.418 * this.hImg + this.hI + this.adj;
          console.log("bgOffset: " + this.bgOffset);
          console.log("aboutUsPos: " + aboutUsPos);
          sheet.insertRule(
              `@keyframes BG-DOWN {
                    from {
                      transform: translateY(` + this.bgOffset + `px);
                    }
                    to {
                      transform: translateY(` + aboutUsPos + `px);
                  }`,0
          )

          let projectPanel = document.getElementById("project-panel-background")
          let aniDuration = 2;
          if (this.bgPosY !== 99999999) {
            aniDuration = 2.5;
          }
          projectPanel.style.animation = `${aniDuration}s ease 0s normal forwards BG-DOWN`;

          this.resizeBG(this.getAvailableContent());
          aniDuration = 0;
          document.getElementById("about-us-content").style.animation = `2s ease ${aniDuration}s normal forwards fadeIn`;

          this.bgOffset = aboutUsPos;
          window.bgPosY = -20;
          this.bgPosY = -20;
        } else if (page === "career") {
          // 隐藏home和about-us
          document.getElementById('home-content').style.display = "none";
          document.getElementById('about-us-content').style.display = "none";
          document.getElementById('career-content').style.display = "block";

          let aniStyle = document.createElement('style');
          aniStyle.setAttribute('type', 'text/css');
          document.head.appendChild(aniStyle)
          let sheet = aniStyle.sheet;
          let careerPos = -0.582 * this.hImg + this.adj;
          if (this.$isMobile()) {
            careerPos -= 75;
          }
          console.log("bgOffset: " + this.bgOffset);
          console.log("careerPos: " + careerPos);
          sheet.insertRule(
              `@keyframes BG-UP {
                    from {
                      transform: translateY(` + this.bgOffset + `px);
                    }
                    to {
                      transform: translateY(` + careerPos + `px);
                  }`,0
          )


          let projectPanel = document.getElementById("project-panel-background")
          let aniDuration = 2;
          if (this.bgPosY !== 99999999) {
            aniDuration = 2.5;
          }
          projectPanel.style.animation = `${aniDuration}s ease 0s normal forwards BG-UP`;

          this.resizeBG(this.getAvailableContent());
          aniDuration = 0;
          document.getElementById("career-content").style.animation = `2s ease ${aniDuration}s normal forwards fadeIn`;

          this.bgOffset = careerPos;
          window.bgPosY = -160
          this.bgPosY = -160
        }
      })
    },
    jumpTo() {
      if (this.isWechat()) {
        // let deb = document.getElementById("test-output")
        // deb.innerText = "是微信内置浏览器"
        window.location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5ODc0NjczOQ==&scene=124#wechat_redirect";
      } else {
        // let deb = document.getElementById("test-output")
        // deb.innerText = "不是微信内置浏览器" + navigator.userAgent
      }
    },
    isWechat() {
      return navigator.userAgent.includes("MicroMessenger");
    }
  }
}
</script>

<style>
.lilyn-dialog {
  background: transparent !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.el-dialog__wrapper {
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
.el-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 6px;
}
.el-form-item__label {
  color: white;
  text-align: left;
  width: 100% !important;
}
.el-form-item__content {
  margin-left: 0px !important;
  width: 100%;
}
input[type='text'] {
  color: white;
  border-radius: 8px !important;
  background: rgba(255, 255, 255, 0.12);
}
.el-select {
  width: 100%;
}
.el-dialog__headerbtn {
  font-size: 30px;
}
.el-dialog__headerbtn .el-dialog__close {
  color: white;
}
</style>

<style scoped>
.headline-bar {
  background: transparent;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  position: fixed;
  width: 100%;
  height: 37px;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
}
.headline-bar-item {
  text-decoration: none;
  cursor: pointer;
}
.headline-bar-play-symbol {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: white;
  letter-spacing: 8px;
}
.headline-bar-text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 800;
  color: white;
  letter-spacing: 2px;
}
.customerform-button {
  width: 100%;
  background: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 20px;
}
/* 主要部分 */
.project-panel {
  background-position-y: center;
  overflow: hidden;
}
.project-panel .project-panel-background {
  width: 100vw;
  height: 100%;
  overflow: visible;
  display: inline;
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
}
.project-panel .page-title {
  top: -2vh;
  opacity: 1;
  transition: opacity 2s;
}
.project-panel #about-us-content {
  top: 88vh;
  opacity: 0;
  margin: 50px auto;
}
.project-panel #career-content {
  top: 80vh;
  opacity: 0;
}
.project-panel .content {
  overflow-x: hidden;
  -ms-overflow-x: hidden;
  overflow-y: hidden;
  -ms-overflow-y: hidden;
}

/* 关于我们部分 */
.project-panel .about-us {
  text-align: center;
  margin: 100px 19vw;
}
.project-panel .about-us .lilyn-text {
  width: 284px;
  height: 65px;
  position: relative;
  margin: auto;
}
.project-panel .about-us .lilyn-subtext {
  font-size: 16px;
  line-height: 50px;
  top: 0px;
  position: relative;
  margin: auto;
  color: #919191;
}
.project-panel .about-us h5 {
  font-size: 1.35vw;
  line-height: 3.7vh;
  /*font-weight: bold;*/
  margin: 1.3vh auto;
  letter-spacing: 2px;
}
.project-panel .about-us p {
  font-size: 1.15vw;
  line-height: 3.1vh;
  margin: 0.17vh auto;
  text-align: justify;
  text-justify: inter-ideograph;
  text-indent: 2em;
  /*letter-spacing: 1px;*/
}
.project-panel .about-us .third-party-zone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  margin-top: 4.42vh;
  z-index: 99999999;
}
.project-panel .about-us .third-party-zone .weixin-link {
  width: 2.55vw;
  /*height: 40px;*/
  margin-right: 2.59vh;
}
.project-panel .about-us .third-party-zone .weixin-link:hover {
  cursor: pointer;
}
.project-panel .about-us .third-party-zone .sina-link {
  width: 2.45vw;
  /*height: 40px;*/
}
.project-panel .about-us .third-party-zone .sina-link:hover {
  cursor: pointer;
}

/* 加入我们部分 */

.project-panel .join-us {
  text-align: center;
  margin: auto 19vw;
}
.project-panel .join-us .lilyn-text {
  width: 284px;
  height: 65px;
  position: relative;
  margin: auto;
}
.project-panel .join-us .lilyn-subtext {
  font-size: 16px;
  line-height: 50px;
  top: 0px;
  position: relative;
  margin: auto;
  color: #919191;
}
.project-panel .join-us h5 {
  font-size: 28px;
  line-height: 3.7vh;
  /*font-weight: bold;*/
  margin: 1.5vh auto;
  letter-spacing: 2px;
}
.project-panel .join-us p {
  font-size: 20px;
  line-height: 3.1vh;
  margin: 1.5vh auto;
  text-align: center;
  /*text-justify: inter-ideograph;*/
  /*text-indent: 2em;*/
  /*letter-spacing: 1px;*/
}
.career-grid {
  margin-top: 2.8vh;
  margin-bottom: 2.8vh;
}
.career-grid-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 2.4vh auto;
}

.career-grid-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.career-grid-item {
  font-size: 1.35vw;
}

.career-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  margin-top: 3.2vh;
}

.hr-qrcode {
  width: 102px;
  height: 102px;
}

.hr-contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 20px;
  height: 102px;
  justify-content: space-around;
}

.contact-text {
  font-size: 1.35vw !important;
  margin:0 0 !important;
  text-align: left !important;
}

.project-panel .hidden {
  opacity: 0;
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  .load-panel {
    background-image: url("https://cdn.lilyn.cn/images/lilyn-overlay.svg") !important;
    /*width: auto;*/
    object-fit: contain;
    background-size: cover;
  }
  .project-panel .content {
    /*overflow-y: scroll;*/
    /*-ms-overflow-y: scroll;*/
    height: 100vh;
  }
  .project-panel .page-title {
    top: 67vh;
  }
  .project-panel .about-us h5 {
    font-size: 20px !important;
    line-height: 36px !important;;
    margin: 0.7vh auto;
    letter-spacing: 0px;
  }
  .project-panel .about-us p {
    font-size: 14px !important;;
    line-height: 26px !important;;
    margin: 0.7vh auto;
  }
  .project-panel .about-us .third-party-zone-inner {
    display: flex !important;
  }
  .project-panel .about-us .third-party-zone-outer {
    display: none !important;
  }
  .project-panel .join-us {
    height: 60vh !important;
    overflow-y: scroll;
    -ms-overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .project-panel .join-us::-webkit-scrollbar {
    display: none;
  }
  .project-panel .join-us h5 {
    font-size: 20px !important;
    line-height: 36px !important;
    margin: 1.5vh auto;
    letter-spacing: 2px;
  }
  .project-panel .join-us p {
    font-size: 14px !important;
    line-height: 26px !important;
    margin: 1.5vh auto;
    text-align: center;
  }
}

@media screen and (max-aspect-ratio: 1) {
  .project-panel .about-us h5 {
    font-size: 20px !important;
    line-height: 36px !important;;
    margin: 0.7vh auto;
    letter-spacing: 0px;
  }
  .project-panel .about-us p {
    font-size: 14px !important;;
    line-height: 26px !important;;
    margin: 0.7vh auto;
  }
}

@media screen and (max-width: 768px) {
  .load-panel {
    background-image: url("https://cdn.lilyn.cn/images/lilyn-overlay-mob.svg");
  }
  .project-panel .project-panel-background {
    width: 100vw;
    /*height: 100%;*/
    /*background-size: 300% auto !important;*/
    /*background-position-x: center;*/
  }
  .project-panel .page-title {
    width: 72vw;
    /*top: -2vh;*/
  }
  #about-us-content .inner {
    padding: 0;
    margin-left: -40px;
    margin-right: -40px;
  }
  #career-content .inner {
    padding: 0;
    margin-left: -40px;
    margin-right: -40px;
  }
  .project-panel .about-us-content-details {
    height: 56vh;
    overflow-y: auto;
    -ms-overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .project-panel .about-us-content-details::-webkit-scrollbar {
    display: none;
  }
  .project-panel .about-us .lilyn-text {
    width: 144px;
    margin-bottom: -2vh;
  }
  .project-panel .about-us .lilyn-subtext {
    font-size: 7px;
    line-height: 30px;
  }
  .project-panel .about-us h5 {
    font-size: 20px;
    line-height: 3.6vh;
    margin: 0.7vh auto;
    letter-spacing: 0px;
  }
  .project-panel .about-us p {
    font-size: 14px;
    line-height: 3vh;
    margin: 0.7vh auto;
  }
  .project-panel .about-us .third-party-zone {
    margin-top: 10px;
  }
  .project-panel .about-us .third-party-zone-inner {
    display: none;
  }
  .project-panel .about-us .third-party-zone-outer {
    display: flex;
  }
  .project-panel .about-us .third-party-zone .weixin-link {
    width: 24px;
    margin-right: 3.5vw;
  }
  .project-panel .about-us .third-party-zone .sina-link {
    width: 24px;
  }
  .project-panel .join-us {
    height: 68vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .project-panel .join-us::-webkit-scrollbar {
    display: none;
  }
  .project-panel .join-us .lilyn-text {
    width: 144px;
    margin-bottom: -2vh;
  }
  .project-panel .join-us .lilyn-subtext {
    font-size: 7px;
    line-height: 30px;
  }
  .project-panel .join-us h5 {
    font-size: 20px;
    line-height: 3.6vh;
    margin: 0.7vh auto;
    letter-spacing: 0px;
  }
  .project-panel .join-us p {
    font-size: 14px;
    line-height: 3vh;
    margin: 0.7vh auto;
    text-align: left;
    text-indent: 2em;
  }
  .project-panel #career-content {
    top: 88vh;
    opacity: 0;
  }
  .career-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
  }
  .career-grid-row {
    margin: 10px auto;
    flex-direction: column;
  }
  .career-grid-column {
    margin: 10px auto;
  }
  .column-align-left {
    margin-left: 7vw;
  }
  .column-align-right {
    margin-right: 7vw;
  }
  .career-grid-item {
    font-size: 14px;
  }
  .career-contact {
    margin-top: 5.1vh;
  }
  .hr-qrcode {
    width: 74px;
    height: 74px;
  }
  .hr-contact-info {
    margin-left: 0px;
  }
  .contact-text {
    font-size: 14px !important;
    text-align: left !important;
    margin: 2px 0 !important;
  }
}

@media screen and (min-width: 769px) {
  .project-panel .content {
    display: inline;
  }
  .project-panel .about-us .third-party-zone-inner {
    display: none !important;
  }
  .project-panel .about-us .third-party-zone-outer {
    display: flex;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOutWithDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
@keyframes fadeOutWithUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>