<template>
  <div class="page">
    <div id="mast" class="header">
      <div id="menu-toggle" @click="toggleOverlay()">
        <img src="../assets/images/menu.svg" alt="menu">
      </div>
      <div>
<!--        <p style="margin-left: 120px"></p>-->
      </div>

      <navbar></navbar>

      <h1  class="sr-text">{{ $t('title') }}</h1>
      <div id="identity">
        <a class="logo" href="/">
          <img src="https://cdn.lilyn.cn/images/logo-transparent.svg" alt="lilyn-logo">
          <span class="sr-text">{{ $t('title') }}</span>
        </a>
      </div>
    </div>
      <router-view>
        <transition :name="transitionName">
        </transition>
      </router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/layout/Footer'
import Navbar from "@/layout/Navbar";
import { globalBus } from "@/assets/js/globalBus";

export default {
  name: "Layout",
  data: function () {
    return {
      transitionName: ''
    }
  },
  created() {
    this.switchTo();
  },
  components: {
    Footer,
    Navbar
  },
  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }
  },
  methods: {
    toggleOverlay: function() {
      const toggle = document.getElementById('menu-toggle');
      const overlay = document.getElementById('nav-panel');
      const logo = document.getElementById('identity');
      const nephilimsLogo = document.getElementById('nephilims-logo');

      if (overlay.classList.contains('active')) {

        toggle.classList.remove('active');

        overlay.classList.remove('active');

        logo.classList.remove('white-tint');

        nephilimsLogo.style.visibility='visible';

        setTimeout(function() {
          overlay.classList.add('hidden');
          toggle.classList.remove('active');
          logo.classList.remove('white-tint');
        }, 700);

        this.bodyunlock();

      } else {

        this.bodylock();

        toggle.classList.add('active');
        overlay.classList.add('active');
        logo.classList.add('white-tint');
        overlay.classList.remove('hidden');
        nephilimsLogo.style.visibility='hidden';

      }

    },
    bodylock: function() {

      // var doc = document.documentElement;
      let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      document.body.classList.add('no-scroll');
      document.body.style.top = (-scrollPosition) + 'px';

      document.body.addEventListener('touchmove', this.prevdefault, {
        passive: false
      });
    },
    bodyunlock: function() {

      document.body.classList.remove('no-scroll');
      document.body.style.top = '';

      document.body.removeEventListener('touchmove', this.prevdefault, false);
    },
    prevdefault: function(e) {
      e.preventDefault();
    },
    switchTo() {
      globalBus.$on('switchTo', (page) => {
        console.log("also switched to" + page);
        this.toggleOverlay();
        this.bodyunlock();
      })
    }
  }
}
</script>

<style>
@media screen and (max-width: 768px) {
  #menu-toggle {
    top: 70px;
    left: 50px;
  }
  .logo {
    width: 65px;
  }
  #identity {
    width: 45px;
  }
}
</style>