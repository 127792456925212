<template>
  <div>
    <div id="nephilims-logo"></div>
    <div class="project-panel" id="project-panel" style="">
      <div class="content" id="content">
        <div class="inner">
          <div class="about-us">
            <img class="lilyn-text" src="http://cdn.lilyn.cn/images/lilyn-font.svg" alt="lilyn-text">
            <span class="lilyn-subtext">LILYN ENTERTAINMENT</span>
            <h5>加入我们</h5>
            <p>我们一直在寻找热爱合作的人才。如果您对动画充满热情并有兴趣加入我们，请联系我们。</p>
            <div class="career-grid">
              <div class="career-grid-row">
                <div class="career-grid-column">
                  <div class="career-grid-item">执行导演</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">动态分镜师</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">科幻场景/角色概念</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">影视动画编剧</div>
                </div>
              </div>
              <div class="career-grid-row">
                <div class="career-grid-column">
                  <div class="career-grid-item">科幻文学策划</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">制片助理</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">漫画上色助理</div>
                </div>
                <div class="career-grid-column">
                  <div class="career-grid-item">漫画勾线助理</div>
                </div>
              </div>
            </div>
            <div class="career-contact">
              <div class="hr-qrcode-div">
                <img class="hr-qrcode" src="http://cdn.lilyn.cn/images/qrcode.png" alt="HR二维码">
              </div>
              <div class="hr-contact-info">
                <p class="contact-text">邮箱：jobs@lilyn.cn</p>
                <p class="contact-text">微信：Anges666-</p>
                <p class="contact-text">电话：13736232143</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "careers",
  mounted() {
    const image = 'http://cdn.lilyn.cn/images/bg.webp';
    this.load(image).then(() => {
      document.getElementById('project-panel').style.backgroundImage = `url(${image})`;
    });
    if (window.bgPosY === 99999999) {
      let aniStyle = document.createElement('style');
      aniStyle.setAttribute('type', 'text/css');
      document.head.appendChild(aniStyle)
      let sheet = aniStyle.sheet;
      sheet.insertRule(
          `@keyframes BG-UP {
            from {
              background-position-y: center;
            }
            to {
              background-position-y: -160vh;
          }`,0
      )

      let projectPanel = document.getElementById("project-panel")
      // projectPanel.style.backgroundPositionY = "center";
      projectPanel.style.animation = "BG-UP 3s ease";

      window.bgPosY = -160
    } else {
      let aniStyle = document.createElement('style');
      aniStyle.setAttribute('type', 'text/css');
      document.head.appendChild(aniStyle)
      let sheet = aniStyle.sheet;
      sheet.insertRule(
          `@keyframes BG-UP {
            from {
              background-position-y: -20vh;
            }
            to {
              background-position-y: -160vh;
          }`,0
      )

      let projectPanel = document.getElementById("project-panel")
      // projectPanel.style.backgroundPositionY = "-160vh";
      projectPanel.style.animation = "BG-UP 3s ease";

      window.bgPosY = -160
    }

    let content = document.getElementById("content");
    content.style.animation = "2s ease 3s normal forwards fadeIn";
  },
  methods: {
    load(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
      })
    }
  }
}
</script>

<style scoped>
.project-panel {
  background-position-y: -160vh;
}
.project-panel .content {
  top: 80vh;
  opacity: 0;
}
.project-panel .about-us {
  text-align: center;
  margin: auto 19vw;
}
.project-panel .about-us .lilyn-text {
  width: 284px;
  height: 65px;
  position: relative;
  margin: auto;
}
.project-panel .about-us .lilyn-subtext {
  font-size: 16px;
  line-height: 50px;
  top: 0px;
  position: relative;
  margin: auto;
  color: #919191;
}
.project-panel .about-us h5 {
  font-size: 26px;
  line-height: 40px;
  /*font-weight: bold;*/
  margin: 16px auto;
  letter-spacing: 2px;
}
.project-panel .about-us p {
  font-size: 22px;
  line-height: 33px;
  margin: 16px auto;
  text-align: center;
  /*text-justify: inter-ideograph;*/
  /*text-indent: 2em;*/
  /*letter-spacing: 1px;*/
}
.career-grid {
  margin-top: 70px;
  margin-bottom: 70px;
}
.career-grid-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 24px auto;
}

.career-grid-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.career-grid-item {
  font-size: 26px;
}

.career-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}

.hr-qrcode {
  width: 102px;
  height: 102px;
}

.hr-contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 20px;
}

.contact-text {
  font-size: 26px !important;
  margin:0 0 !important;
  text-align: left !important;
}

.project-panel .hidden {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>