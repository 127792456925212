<template>
    <div class="gargantuanTitle">

        <svg class="gargantuanTitle-left" width="112" height="11" viewBox="0 0 112 11" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H59L67.5 9.5H110.5" stroke="url(#paint0_linear_623_1590)" stroke-width="1.5"
                stroke-linecap="round" />
            <defs>
                <linearGradient id="paint0_linear_623_1590" x1="112" y1="9.99998" x2="-5" y2="0.999979"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292A33" />
                    <stop offset="1" stop-color="#292A33" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

        <div class="gargantuanTitle-content text-1">
            {{ text }}
        </div>

        <svg class="gargantuanTitle-right" width="112" height="11" viewBox="0 0 112 11" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M110.5 1H52.5L44 9.5H1" stroke="url(#paint0_linear_623_1591)" stroke-width="1.5"
                stroke-linecap="round" />
            <defs>
                <linearGradient id="paint0_linear_623_1591" x1="-0.50001" y1="9.99998" x2="116.5" y2="0.999979"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#292A33" />
                    <stop offset="1" stop-color="#292A33" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'Title',
    props: {
        text: String
    }
}
</script>
<style lang="scss">
.gargantuanTitle {
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
        position: relative;
        font-weight: 400;
        font-family: WD-XL, sans-serif;
        letter-spacing: 0.1em;
        padding: 16px;
        background-image: url('https://cdn.lilyn.cn/images/juwu/promote/header-title-left-top.svg'), url('https://cdn.lilyn.cn/images/juwu/promote/header-title-right-bottom.svg');
        background-position: left top, right bottom;
        background-size: 20% 20%, 20% 20%;
        background-repeat: no-repeat;
    }
}
</style>