<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>

import "./assets/css/animate.css"
import "./assets/css/core.css"
import "./assets/css/fonts.css"
import "./assets/css/forms.css"
import "./assets/css/grid.css"
import "./assets/css/app.css"
import './assets/css/variables.scss'
// import "./assets/js/reveal.js"

export default {
  name: "App",
  data: function () {
    return {
      matcher: null,
      currentCountry: null
    }
  },
  mutations: {
  },
  created() {
    this.detectedLanguage()
    document.title = this.$t("title").toString();
    let cdnprefetch = document.createElement('link')
    cdnprefetch.setAttribute('rel', 'dns-prefetch')
    cdnprefetch.setAttribute('href', 'https://cdn.lilyn.cn')
    document.head.appendChild(cdnprefetch)
  },
  methods: {
    async detectedLanguage() {
      const respObj = await fetch('https://api.ipify.org?format=json', { method: 'GET' })
      const data = await respObj.json()
      const userIPAddress = data.ip
      this.$cookies.set('localIp', userIPAddress, '1day')
      console.log(`User IP: ${userIPAddress}`)

      let resp = await fetch(`https://ddns.lilyn.cn:12443/api/loc?ip=${userIPAddress}`, { method: 'GET' })
      let geoJson = await resp.json()
      console.log(geoJson)

      if (geoJson['data']['countryCode'] !== 'CN') {
        this.$message({
          customClass: 'lang-suggest-msg',
          dangerouslyUseHTMLString: true,
          message: '<a href="https://i.lilyn.cn">Click here to international website</a>',
          duration: 0,
          offset: 40,
        });
      }
    }
  }
}
</script>

<style>
.lang-suggest-msg {
  z-index: 9999999999 !important;
}
</style>