import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import App from "./App";

import zh from "./lang/zh";
import en from "./lang/en";
import Layout from "@/layout";
import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Message,
  MessageBox,
  Dialog,
  Notification,
  Popover,
} from "element-ui";
import home from "@/views/home";
import about from "@/views/about";
import careers from "@/views/careers";
import VueMobileDetection from "vue-mobile-detection";
import VueCookies from "vue-cookies";
import VueVideoPlayer from "vue-video-player";

import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import gargantuan from "@/views/gargantuan/gargantuan.vue";

import VueGtag from "vue-gtag";

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popover);
Vue.use(VueMobileDetection);
Vue.use(VueCookies);
Vue.use(Dialog);
Vue.use(VueVideoPlayer);
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$alert = MessageBox.alert;

Vue.config.ignoredElements = [/^yt-/];

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Layout,
      children: [
        { path: "", name: "home", component: home },
        { path: "about", name: "about", component: about },
        { path: "careers", name: "careers", component: careers },
      ],
    },
    { path: "/gargantuan", name: "gargantuan", component: gargantuan },
    // {path: '*', component: NotFound}
  ],
});

const current = router.history._startLocation;
if (current === "/") {
  console.log("current path:", current);
  Vue.use(
    VueGtag,
    {
      config: {
        id: "G-6YMBJLG3VD",
        params: {
          send_page_view: false,
        },
      },
    },
    router
  );
} else if (current === "/gargantuan") {
  console.log("current path:", current);
  Vue.use(
    VueGtag,
    {
      config: {
        id: "G-KY9K1B83D1",
        params: {
          send_page_view: false,
        },
      },
    },
    router
  );
}

let locale = window.localStorage.lang;
if (!locale) {
  // let lang = navigator.language;
  // if (lang.startsWith('zh')) {
  //     locale = 'zh';
  // } else {
  //     locale = 'en';
  // }
  locale = "zh";
}
const i18n = new VueI18n({
  locale,
  fallbackLocale: "zh",
  messages: {
    zh,
    en,
  },
});

new Vue({
  render: (h) => h(App),
  router,
  i18n,
}).$mount("#app");
