<template>
  <div>
    <div id="nephilims-logo"></div>
    <div class="project-panel" id="project-panel" style="">
      <div class="content" id="content">
        <div class="inner">
          <div class="about-us">
            <img class="lilyn-text" src="http://cdn.lilyn.cn/images/lilyn-font.svg" alt="lilyn-text">
            <span class="lilyn-subtext">LILYN ENTERTAINMENT</span>
            <h5>我们的愿景</h5>
            <p>理灵文化传播有限公司致力于动画、漫画、影视的制作，原创IP的孵化。目前公司正在制作原创科幻动画与科幻漫画。</p>
            <h5>我们的团队</h5>
            <p>我们的动画团队成员来自追光动画、艺画开天、黑岩动画、娃娃鱼动画、初色动画、米粒影业等国内知名动画公司。</p>
            <p>团队成员毕业于北京电影学院、中央美术学院、中国美术学院等国内一流艺术学院，以及UCSD、Pratt、IIT等海外一流院校。</p>
            <p>团队成员曾参与过《三体》、《灵笼》、《青蛇劫起》、《哪吒-重生》、英雄联盟CG《乘风归》、《龙之谷电影版》、《星骸骑士》、《新世纪福音战士》、《剑风传奇》等国内外一线动画制作。具备专业的动画美术功底与动画项目经验。编剧团队多次参与入围国际影展与国际电影节。</p>

            <div class="third-party-zone">
              <img class="weixin-link" src="https://lilyn-web-assets.oss-cn-hangzhou.aliyuncs.com/images/weixin.svg" alt="weixin-link" onclick="javascript:window.location('https://www.qq.com')">
              <img class="sina-link" src="https://lilyn-web-assets.oss-cn-hangzhou.aliyuncs.com/images/sina.svg" alt="sina-link">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  mounted() {
    const image = 'http://cdn.lilyn.cn/images/bg.webp';
    this.load(image).then(() => {
      document.getElementById('project-panel').style.backgroundImage = `url(${image})`;
    });

    if (window.bgPosY === 99999999) {
      let aniStyle = document.createElement('style');
      aniStyle.setAttribute('type', 'text/css');
      document.head.appendChild(aniStyle)
      let sheet = aniStyle.sheet;
      sheet.insertRule(
          `@keyframes BG-DOWN {
            from {
              background-position-y: center;
            }
            to {
              background-position-y: -20vh;
          }`,0
      )

      let projectPanel = document.getElementById("project-panel")
      // projectPanel.style.backgroundPositionY = "center";
      projectPanel.style.animation = "BG-DOWN 3s ease";

      window.bgPosY = -20
    } else {
      let aniStyle = document.createElement('style');
      aniStyle.setAttribute('type', 'text/css');
      document.head.appendChild(aniStyle)
      let sheet = aniStyle.sheet;
      sheet.insertRule(
          `@keyframes BG-DOWN {
            from {
              background-position-y: -160vh;
            }
            to {
              background-position-y: -20vh;
          }`,0
      )

      let projectPanel = document.getElementById("project-panel")
      // projectPanel.style.backgroundPositionY = "-160vh";
      projectPanel.style.animation = "BG-DOWN 3s ease";

      window.bgPosY = -20
    }

    let content = document.getElementById("content");
    content.style.animation = "2s ease 3s normal forwards fadeIn";
  },
  methods: {
    load(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
      })
    },
  }
}
</script>

<style scoped>
.project-panel {
  background-position-y: -20vh;
  animation: BG-DOWN 5s ease;
}
.project-panel .content {
  top: 88vh;
  opacity: 0;
}
.project-panel .about-us {
  text-align: center;
  margin: auto 19vw;
}
.project-panel .about-us .lilyn-text {
  width: 284px;
  height: 65px;
  position: relative;
  margin: auto;
}
.project-panel .about-us .lilyn-subtext {
  font-size: 16px;
  line-height: 50px;
  top: 0px;
  position: relative;
  margin: auto;
  color: #919191;
}
.project-panel .about-us h5 {
  font-size: 26px;
  line-height: 40px;
  /*font-weight: bold;*/
  margin: 16px auto;
  letter-spacing: 2px;
}
.project-panel .about-us p {
  font-size: 22px;
  line-height: 33px;
  margin: 16px auto;
  text-align: justify;
  text-justify: inter-ideograph;
  text-indent: 2em;
  /*letter-spacing: 1px;*/
}
.project-panel .about-us .third-party-zone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}
.project-panel .about-us .third-party-zone .weixin-link {
  width: 49px;
  height: 40px;
  margin-right: 28px;
}
.project-panel .about-us .third-party-zone .weixin-link:hover {
  cursor: pointer;
}
.project-panel .about-us .third-party-zone .sina-link {
  width: 47px;
  height: 40px;
}
.project-panel .about-us .third-party-zone .sina-link:hover {
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>